import { useSessionStorage } from '@vueuse/core';

export function usePromoCode () {
    const route = useRoute();
    const sessionPromoCode = useSessionStorage<string | undefined>('promo-code', '');

    watch(() => route.query.promo_code, (newPromoCode) => {
        if (Array.isArray(newPromoCode)) {
            newPromoCode = newPromoCode[0];
        }

        // if the promo code is empty then don't override the existing one
        if (newPromoCode) {
            sessionPromoCode.value = newPromoCode;
        }
    }, { immediate: true });

    function clearPromoCode () {
        sessionPromoCode.value = undefined;
    }

    return {
        promoCode: sessionPromoCode,
        clearPromoCode,
    };
}
