import { default as _404XNrS9N3h6DMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_46_46_46slug_934v80meQVqFMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _91_91filter_93_93t0zz5UgpyqMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexpSOUS0ImHQMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notificationss6S99qInA0Meta } from "/codebuild/output/src1608076570/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as paymentsrfDqfcTBQPMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infosl3o6ZB0B0Meta } from "/codebuild/output/src1608076570/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securitygMFyicG6NwMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirmYPdAG915hVMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexor8PXuif2UMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_936CecjsSTlqMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93ApRzqTG1IEMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as loginpXRdYj0F3eMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45password8X28pezB60Meta } from "/codebuild/output/src1608076570/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordQEZwj9qd8wMeta } from "/codebuild/output/src1608076570/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: _404XNrS9N3h6DMeta?.name ?? "404",
    path: _404XNrS9N3h6DMeta?.path ?? "/404",
    meta: _404XNrS9N3h6DMeta || {},
    alias: _404XNrS9N3h6DMeta?.alias || [],
    redirect: _404XNrS9N3h6DMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_934v80meQVqFMeta?.name ?? "slug",
    path: _91_46_46_46slug_934v80meQVqFMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_934v80meQVqFMeta || {},
    alias: _91_46_46_46slug_934v80meQVqFMeta?.alias || [],
    redirect: _91_46_46_46slug_934v80meQVqFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_93t0zz5UgpyqMeta?.name ?? "account-bookings-filter",
    path: _91_91filter_93_93t0zz5UgpyqMeta?.path ?? "/account/bookings/:filter?",
    meta: _91_91filter_93_93t0zz5UgpyqMeta || {},
    alias: _91_91filter_93_93t0zz5UgpyqMeta?.alias || [],
    redirect: _91_91filter_93_93t0zz5UgpyqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/account/bookings/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: indexpSOUS0ImHQMeta?.name ?? "account",
    path: indexpSOUS0ImHQMeta?.path ?? "/account",
    meta: indexpSOUS0ImHQMeta || {},
    alias: indexpSOUS0ImHQMeta?.alias || [],
    redirect: indexpSOUS0ImHQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationss6S99qInA0Meta?.name ?? "account-notifications",
    path: notificationss6S99qInA0Meta?.path ?? "/account/notifications",
    meta: notificationss6S99qInA0Meta || {},
    alias: notificationss6S99qInA0Meta?.alias || [],
    redirect: notificationss6S99qInA0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: paymentsrfDqfcTBQPMeta?.name ?? "account-payments",
    path: paymentsrfDqfcTBQPMeta?.path ?? "/account/payments",
    meta: paymentsrfDqfcTBQPMeta || {},
    alias: paymentsrfDqfcTBQPMeta?.alias || [],
    redirect: paymentsrfDqfcTBQPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/account/payments.vue").then(m => m.default || m)
  },
  {
    name: personal_45infosl3o6ZB0B0Meta?.name ?? "account-personal-info",
    path: personal_45infosl3o6ZB0B0Meta?.path ?? "/account/personal-info",
    meta: personal_45infosl3o6ZB0B0Meta || {},
    alias: personal_45infosl3o6ZB0B0Meta?.alias || [],
    redirect: personal_45infosl3o6ZB0B0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/account/personal-info.vue").then(m => m.default || m)
  },
  {
    name: securitygMFyicG6NwMeta?.name ?? "account-security",
    path: securitygMFyicG6NwMeta?.path ?? "/account/security",
    meta: securitygMFyicG6NwMeta || {},
    alias: securitygMFyicG6NwMeta?.alias || [],
    redirect: securitygMFyicG6NwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: confirmYPdAG915hVMeta?.name ?? "experiences-parentId-serviceId-confirm",
    path: confirmYPdAG915hVMeta?.path ?? "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmYPdAG915hVMeta || {},
    alias: confirmYPdAG915hVMeta?.alias || [],
    redirect: confirmYPdAG915hVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexor8PXuif2UMeta?.name ?? "experiences-parentId-serviceId",
    path: indexor8PXuif2UMeta?.path ?? "/experiences/:parentId()/:serviceId()",
    meta: indexor8PXuif2UMeta || {},
    alias: indexor8PXuif2UMeta?.alias || [],
    redirect: indexor8PXuif2UMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_936CecjsSTlqMeta?.name ?? "experiences-parentId-serviceId-return-bookingId",
    path: _91bookingId_936CecjsSTlqMeta?.path ?? "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_936CecjsSTlqMeta || {},
    alias: _91bookingId_936CecjsSTlqMeta?.alias || [],
    redirect: _91bookingId_936CecjsSTlqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91accessKey_93ApRzqTG1IEMeta?.name ?? "gratuity-accessKey",
    path: _91accessKey_93ApRzqTG1IEMeta?.path ?? "/gratuity/:accessKey()",
    meta: _91accessKey_93ApRzqTG1IEMeta || {},
    alias: _91accessKey_93ApRzqTG1IEMeta?.alias || [],
    redirect: _91accessKey_93ApRzqTG1IEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/gratuity/[accessKey].vue").then(m => m.default || m)
  },
  {
    name: loginpXRdYj0F3eMeta?.name ?? "login",
    path: loginpXRdYj0F3eMeta?.path ?? "/login",
    meta: loginpXRdYj0F3eMeta || {},
    alias: loginpXRdYj0F3eMeta?.alias || [],
    redirect: loginpXRdYj0F3eMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45password8X28pezB60Meta?.name ?? "reset-password",
    path: reset_45password8X28pezB60Meta?.path ?? "/reset-password",
    meta: reset_45password8X28pezB60Meta || {},
    alias: reset_45password8X28pezB60Meta?.alias || [],
    redirect: reset_45password8X28pezB60Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45passwordQEZwj9qd8wMeta?.name ?? "set-password",
    path: set_45passwordQEZwj9qd8wMeta?.path ?? "/set-password",
    meta: set_45passwordQEZwj9qd8wMeta || {},
    alias: set_45passwordQEZwj9qd8wMeta?.alias || [],
    redirect: set_45passwordQEZwj9qd8wMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1608076570/src/gx-booking/pages/set-password.vue").then(m => m.default || m)
  }
]