<script setup>
import '~/assets/css/expond-icons/style.css';

useUTM();
usePromoCode();

const { isAccountDirect } = useAppMode();

useHead({
    title: 'Go Expond',
    meta: [
        {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        },
        isAccountDirect
            ? {
                name: 'robots',
                content: 'noindex, nofollow',
            }
            : undefined,
    ],
});
onMounted(() => {
    window.parcelRequire = null;

    // disable pinch zooming on iOS
    document.addEventListener('touchmove', function (event) {
        if (event.scale !== undefined && event.scale !== 1) {
            event.preventDefault();
        }
    }, { passive: false });
});
</script>

<template>
    <NuxtLayout>
        <Head>
            <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <Link rel="manifest" href="/site.webmanifest" />
            <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <Meta name="msapplication-TileColor" content="#2b5797" />
            <Meta name="theme-color" content="#ffffff" />
        </Head>
        <NuxtPage />
    </NuxtLayout>
</template>

<style lang='scss'>
@import 'App.scss';
</style>
