import revive_payload_client_jKbhjUTLkT from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_apYYDVGRmQ from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bkbRX8kY7E from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_7zNmuBaWmG from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1608076570/src/gx-booking/.nuxt/components.plugin.mjs";
import unhead_y9Ovi5QJpP from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_J2YhCUJG8r from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_8oewVQWYWy from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_vue-router@4.2.4_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_LGAXMCmHZQ from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.2_vue-router@4.2.4_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_GUwr6SCDSM from "/codebuild/output/src1608076570/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_Wjz9XrZo01 from "/codebuild/output/src1608076570/src/gx-booking/plugins/firebase.ts";
import middleware_global_fxkQLky8iN from "/codebuild/output/src1608076570/src/gx-booking/plugins/middleware.global.ts";
import vuedatepicker_oKNl9XPOyX from "/codebuild/output/src1608076570/src/gx-booking/plugins/vuedatepicker.ts";
export default [
  revive_payload_client_jKbhjUTLkT,
  router_apYYDVGRmQ,
  payload_client_bkbRX8kY7E,
  plugin_vue3_7zNmuBaWmG,
  components_plugin_KR1HBZs4kY,
  unhead_y9Ovi5QJpP,
  prefetch_client_J2YhCUJG8r,
  composition_8oewVQWYWy,
  i18n_LGAXMCmHZQ,
  chunk_reload_client_GUwr6SCDSM,
  firebase_Wjz9XrZo01,
  middleware_global_fxkQLky8iN,
  vuedatepicker_oKNl9XPOyX
]