import { useLocalStorage } from '@vueuse/core';

export function useUTM () {
    const searchParams = new URLSearchParams(window.location.search);
    const currentUtm = {
        source: searchParams.get('utm_source') || undefined,
        medium: searchParams.get('utm_medium') || undefined,
        campaign: searchParams.get('utm_campaign') || undefined,
        term: searchParams.get('utm_term') || undefined,
        content: searchParams.get('utm_content') || undefined,
    };

    const utmItems = useLocalStorage('utm', {});
    const hasUtm = computed(() => Object.entries(utmItems.value).filter(([, value]) => value !== undefined).length > 0);

    // filter out undefined values
    const filteredCurrentUtm = Object.fromEntries(
        Object.entries(currentUtm).filter(([, value]) => value !== undefined)
    );

    // combine with existing items, overriding with current URL values
    utmItems.value = {
        ...utmItems.value,
        ...filteredCurrentUtm,
    };

    // clear utm values
    function clearUtm () {
        utmItems.value = undefined;
    }

    return {
        hasUtm,
        utmItems,
        clearUtm,
    };
}
